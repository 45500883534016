//错误上报
(function(){
	window.onerror = function(msg,url,line){
		var t = document.createElement('img');
		if(!url){
			url=window.location.href;
		}
		if(window.index){
			msg+=("["+index.current_error_dom+"]");
		}else{
			msg+=("[no index]");
		}
		var e_info=encodeURIComponent(msg+'|_|'+url+'|_|'+line+'|_|'+window.navigator.userAgent);
		t.src = 'http://badjs.qq.com/cgi-bin/js_report?bid=67&mid=195232&msg='+e_info;
		t=null;
	}
})();
//经常报这个脚本错误
document.caretRangeFromPoint=function(){};
/**
 * 
 * @fileoverview Simple Javascript Lib Lite, for simple web developer such as im_client or index page 
 * @version 1.0
 * @author webryan,henryguo glancer.h@gmail.com
 * @lastUpdate 2011-01-07 11:40 
 * @function: 1.dom 2.cookie 3.ajax 4.mask?
 * @info: 以后采用文件版本号的方式更新
 */

/**
 * 基础方法
 */
var Simple = window.Simple = window.$ = function(n){
	return document.getElementById(n);
}

/**
 * cookie相关
 */
$.cookie = {
	/**
	 * 读取cookie
	 *
	 * @param {String} n=名称
	 * @return {String} cookie值
	 * @example
	 * 		$.cookie.get('id_test');
	 */
	get:function(n){
		var m = document.cookie.match(new RegExp( "(^| )"+n+"=([^;]*)(;|$)"));   
		return !m ? "":decodeURIComponent(m[2]);  
	},
	/**
	 * 设置cookie
	 * @param {String} name cookie名称 --必填
	 * @param {String} value cookie值  --必填
	 * @param {String} domain 所在域名
	 * @param {String} path 所在路径
	 * @param {Number} hour 存活时间，单位:小时
	 * @example
	 *  $.cookie.set('value1','cookieval',"id.qq.com","/test",24); //设置cookie
	 */
	set:function(name,value,domain,path,hour){ 
		var expire = new Date(); 
		expire.setTime(expire.getTime() + (hour?3600000 * hour:30*24*60*60*1000));

		document.cookie = name + "=" + value + "; " + "expires=" + expire.toGMTString()+"; path="+ (path ? path :"/")+ "; "  + (domain ? ("domain=" + domain + ";") : ""); 
	},
		/**
	 * 设置会话cookie
	 * @param {String} name cookie名称 --必填
	 * @param {String} value cookie值  --必填
	 * @param {String} domain 所在域名
	 * @param {String} path 所在路径
	 * @example
	 *  $.cookie.set('value1','cookieval',"id.qq.com","/test"); //设置会话cookie
	 */
	setSessionCookie:function(name,value,domain,path){ 
		document.cookie = name + "=" + value + "; path="+ (path ? path :"/")+ "; "  + (domain ? ("domain=" + domain + ";") : ""); 
	},
	/**
	 * 删除指定cookie,复写为过期 !!注意path要严格匹配， /id 不同于/id/
	 *
	 * @param {String} name cookie名称
	 * @param {String} domain 所在域
	 * @param {String} path 所在路径
	 * @example
	 * 		$.cookie.del('id_test'); //删除cookie
	 */
	del : function(name, domain, path) {
		document.cookie = name + "=; expires=Mon, 26 Jul 1997 05:00:00 GMT; path="+ (path ? path :"/")+ "; " + (domain ? ("domain=" + domain + ";") : ""); 
	},
	/**
	 * 删除所有cookie -- 这里暂时不包括目录下的cookie
	 * @example
	 * 		$.cookie.clear(); //删除所有cookie
	 */
	 
	clear:function(){
		var rs = document.cookie.match(new RegExp("([^ ;][^;]*)(?=(=[^;]*)(;|$))", "gi"));
		// 删除所有cookie
		for (var i in rs){
			document.cookie = rs[i] + "=;expires=Mon, 26 Jul 1997 05:00:00 GMT; path=/; " ;
		}
	},
	/**
	 * uin -- 针对业务,对外开源请删除
	 * 
	 * @return {String} uin值
	 * @example
	 * 		$.cookie.uin();
	 */
	uin:function(){
		var u = $.cookie.get("uin");
		return !u?null:parseInt(u.substring(1, u.length),10); 
	},
	//获取申请到的qq号码
	getNewUin:function(){
		var last_page=$.winName.get("last_page");
		$.winName.set("last_page",0);
		var newUin= $.cookie.get("_new_uin");
		if(!newUin){
			newUin=$.winName.get("_new_uin")
		}
		if(!newUin||newUin=="undefined"){
			//刷新页面不上报，正常流程过来才上报
			if(last_page==1){
			$.report.monitor("no_uin");
			}
			window.location.href="error.html?ec=no";
			/**
			var browserType=browser_version();
			switch(browserType){
				case 1:
				$.report.monitor("no_uin_ie");
				break;
				case 3:
				$.report.monitor("no_uin_ff");
				break;
				case 5:
				$.report.monitor("no_uin_chrome");
				break;
				case 7:
				$.report.monitor("no_uin_safari");
				break;
				case 9:
				$.report.monitor("no_uin_opera");
				break;			
			}
			**/
		}
		return $.html.encode(newUin);
	},
	//获取申请号码的邮箱
	getEmail:function(){
		var email= $.cookie.get("_email");
		if(!email){
			email=$.winName.get("_email")
		}
		if(!email){
			$.report.monitor("no_email");
			window.location.href="error.html?ec=no";
		}
		return $.html.encode(email);
	}
};

//http相关
$.http = {
	/**
	 * 创建XMLHttpRequest对象，用于ajax通讯
	 * @example
	 * 		$.http.getXHR(); 
	 */
	getXHR:function(){
		var xmlHttp;
		try {
		  xmlHttp = new XMLHttpRequest();
		} catch (trymicrosoft) {
			  try {
			      xmlHttp = new ActiveXObject("Msxml2.XMLHTTP");
			  } catch (othermicrosoft) {
				  try {
				   xmlHttp = new ActiveXObject("Microsoft.XMLHTTP");
				  } catch (failed) {
				   xmlHttp = false;
				  }
			  }
		}
		return xmlHttp;
	},
	/**
	 * 发出ajax请求
	 *
	 * @param {String} url 请求路径--不能跨域
	 * @param {Object} para 参数列表
	 * @param {Function} cb 回调函数
	 * @param {Function} method 请求方式: [post|get]
	 * @param {String} type 数据类型：[json|text] --默认为json
	 * @example
	 * 		$.http.ajax('/cgi-bin/info',{'uin':10001},fnCallBack,'get');  
	 */
	ajax:function(url,para,cb,method,type,headers){
		var xhr = $.http.getXHR();
		if(!xhr) return;
		xhr.open(method,url);
		for(var name in headers)
			if(headers.hasOwnProperty(name))
				xhr.setRequestHeader(name, headers[name]);
		xhr.onreadystatechange = function(){
			if(xhr.readyState==4){
				//ie error with 1223 and opera with 304 or 0 
				if(( xhr.status >= 200 && xhr.status < 300 ) ||	xhr.status === 304 || xhr.status === 1223 || xhr.status === 0){
					if(typeof(type)=="undefined"){
						cb(eval("("+xhr.responseText+")")); //不容错，以便于排查json错误
					}else{
						cb(xhr.responseText);	
					}
					xhr.onreadystatechange = function(){};
					xhr = null;
				}			
			}else{
				//added by EdisonTsai on 16:23 2016/04/01
				$.report.monitor("submit_crash_"+$GLOBALS['lang']);
			}	
		}
		xhr.send(para);
	},
	/**
	 * 通过ajax发出post请求
	 *
	 * @param {String} url 请求路径--不能跨域
	 * @param {Object} para 参数列表
	 * @param {Function} cb 回调函数 
	 * @param {String} type 数据类型：[json|text] --默认为json
	 * @example
	 * 		$.http.post('/cgi-bin/info_mod',{'uin':10001},fnCallBack);  
	 */
	post:function(url,para,cb,type){
		var s = "";
		for(var i in para){
			s+=i+"="+para[i]+"&";
		}
		$.http.ajax(url,s,cb,"POST",type, {'Content-Type':'application/x-www-form-urlencoded'});
	},
	/**
	 * 通过ajax发出get请求
	 *
	 * @param {String} url 请求路径--不能跨域
	 * @param {Object} para 参数列表
	 * @param {Function} cb 回调函数 
	 * @param {String} type 数据类型：[json|text] --默认为json
	 * @example
	 * 		$.http.get('/cgi-bin/info',{'uin':10001},fnCallBack);  
	 */
	get:function(url,para,cb,type){
		var s = "";
		for(var i in para){
			s+=i+"="+para[i]+"&";
		}
		if(url.indexOf("?")==-1){
			url+="?";	
		}
		if(url[url.length - 1] !== '&')
			url += '&';
		url += s;
		$.http.ajax(url,null,cb,"GET",type);
	},
	/**
	 * jsonp获取回调函数-- 可以支持跨域
	 *
	 * @param {String} url 请求路径
	 * @example
	 * 		$.http.jsonp('http://webryan.net/cgi-bin/test');   //cgi的返回值应该是 fnCallback({'data':''})的形式
	 */
	jsonp:function(url){
		var s = document.createElement("script");
		s.src = url;
		document.getElementsByTagName("head")[0].appendChild(s);  
	},
	/**
	 * 异步加载script脚本,并回调
	 *
	 * @param {String} src 请求路径
	 * @param {Function} callback 加载文档后的回调函数
	 * @example
	 * 		$.http.loadScript('http://webryan.net/js/index.js',function(){alert()});   //callback通常为函数名，非字符串
	 */
	loadScript:function (src, callback) {   
		var tag = document.createElement("script");   
		// Attach handlers for all browsers
		tag.onload = tag.onreadystatechange = function() {
			if ( !this.readyState ||this.readyState === "loaded" || this.readyState === "complete") {
				// 执行回调
				callback();
				// Handle memory leak in IE
				tag.onload = tag.onreadystatechange = null;
				if (tag.parentNode ) {
					tag.parentNode.removeChild( tag );
				}
			}
		};
		
		tag.src = src;   
		document.getElementsByTagName("head")[0].appendChild(tag);   
	},
	/**
	 * 预加载某个文件，包括图片，js,flash --可以用于上报
	 *
	 * @param {String} url 请求路径 
	 * @example
	 * 		$.http.preload('http://webryan.net/swf/friends.swf');  
	 */
	preload:function(url){
		var s = document.createElement("img");
		s.src = url;	
	}
}

/**
 * get发送ajax请求，
 * @see: $.http.get();
 *  
 */
$.get = $.http.get;
/**
 * post发送ajax请求
 * @see: $.http.post();
 *  
 */
$.post = $.http.post;

/**
 * 获取浏览器的版本等信息
 *
 * @param {String} name [type-类型1.msie.2.ff.3.opera.4.webkit|version--版本号] 
 * 
 * @example
 * 		$.browser("");  
 */
$.browser = function(name){
	if(typeof $.browser.info == "undefined"){
		var ret = { type: "" };
		var ua = navigator.userAgent.toLowerCase();
		if ( /webkit/.test( ua ) ) {
			ret = { type: "webkit", version: /webkit[\/ ]([\w.]+)/ };
		} else if ( /opera/.test( ua ) ) {
			ret = { type: "opera", version:  /version/.test( ua ) ? /version[\/ ]([\w.]+)/ : /opera[\/ ]([\w.]+)/ };
		} else if ( /msie/.test( ua ) ) {
			ret = { type: "msie", version: /msie ([\w.]+)/ };
		} else if ( /mozilla/.test( ua ) && !/compatible/.test( ua ) ) {
			ret = { type: "ff", version: /rv:([\w.]+)/ };
		}

		ret.version = (ret.version && ret.version.exec( ua ) || [0, "0"])[1];
		$.browser.info = ret;	
	}
	return $.browser.info[name]
}

/**
 *  事件相关 -- 绑定，解绑，触发
 */
$.e = {
    // Private utility to generate unique handler ids
	_counter :0,
    _uid:function( ) { return "h" + $.e._counter++; },
	add:function(element, eventType, handler){
		if(typeof element != "object"){
			element = $(element);	
		}
		if (document.addEventListener){
			element.addEventListener(eventType, handler, false);
		}else if (document.attachEvent) {
			if ($.e._find(element, eventType, handler) != -1) return;

			// To invoke the handler function as a method of the
			// element, we've got to define this nested function and register
			// it instead of the handler function itself.
			var wrappedHandler = function(e) {
				if (!e) e = window.event;
	
				// Create a synthetic event object with partial compatibility
				// with DOM events.
				var event = {
					_event: e,    // In case we really want the IE event object
					type: e.type,           // Event type
					target: e.srcElement,   // Where the event happened
					currentTarget: element, // Where we're handling it
					relatedTarget: e.fromElement?e.fromElement:e.toElement,
					eventPhase: (e.srcElement==element)?2:3,
	
					// Mouse coordinates
					clientX: e.clientX, clientY: e.clientY,
					screenX: e.screenX, screenY: e.screenY,
				   // Key state --fix:keyCode
					altKey: e.altKey, ctrlKey: e.ctrlKey,
					shiftKey: e.shiftKey, keyCode: e.keyCode,
	
					// Event-management functions
					stopPropagation: function( ) {this._event.cancelBubble = true;},
					preventDefault: function( ) {this._event.returnValue = false;}
				}
	
				// Invoke the handler function as a method of the element, passing
				// the synthetic event object as its single argument.
				// Use Function.call( ) if defined; otherwise do a hack
				if (Function.prototype.call)
					handler.call(element, event);
				else {
					// If we don't have Function.call, fake it like this.
					element._currentHandler = handler;
					element._currentHandler(event);
					element._currentHandler = null;
				}
			};
	
			// Now register that nested function as our event handler.
			element.attachEvent("on" + eventType, wrappedHandler);
	
			// Now we must do some record keeping to associate the user-supplied
			// handler function and the nested function that invokes it.
			// We have to do this so that we can deregister the handler with the
			// remove( ) method and also deregister it automatically on page unload.
	
			// Store all info about this handler into an object.
			var h = {
				element: element,
				eventType: eventType,
				handler: handler,
				wrappedHandler: wrappedHandler
			};
	
			// Figure out what document this handler is part of.
			// If the element has no "document" property, it is not
			// a window or a document element, so it must be the document
			// object itself.
			var d = element.document || element;
			// Now get the window associated with that document.
			var w = d.parentWindow;
	
			// We have to associate this handler with the window,
			// so we can remove it when the window is unloaded.
			var id = $.e._uid( );  // Generate a unique property name
			if (!w._allHandlers) w._allHandlers = {};  // Create object if needed
			w._allHandlers[id] = h; // Store the handler info in this object
	
			// And associate the id of the handler info with this element as well.
			if (!element._handlers) element._handlers = [];
			element._handlers.push(id);
	
			// If there is not an onunload handler associated with the window,
			// register one now.
			if (!w._onunloadHandlerRegistered) {
				w._onunloadHandlerRegistered = true;
				w.attachEvent("onunload", $.e._removeAllHandlers);
			}
		}
	},
	remove:function(element, eventType, handler) {
		if (document.addEventListener){
        	element.removeEventListener(eventType, handler, false);
		}else if (document.attachEvent){
			 // Find this handler in the element._handlers[] array.
			var i = $.e._find(element, eventType, handler);
			if (i == -1) return;  // If the handler was not registered, do nothing
	
			// Get the window of this element.
			var d = element.document || element;
			var w = d.parentWindow;
	
			// Look up the unique id of this handler.
			var handlerId = element._handlers[i];
			// And use that to look up the handler info.
			var h = w._allHandlers[handlerId];
			// Using that info, we can detach the handler from the element.
			element.detachEvent("on" + eventType, h.wrappedHandler);
			// Remove one element from the element._handlers array.
			element._handlers.splice(i, 1);
			// And delete the handler info from the per-window _allHandlers object.
			delete w._allHandlers[handlerId];
		} 
    },
	
	 // A utility function to find a handler in the element._handlers array
    // Returns an array index or -1 if no matching handler is found
    _find : function(element, eventType, handler) {
        var handlers = element._handlers;
        if (!handlers) return -1;  // if no handlers registered, nothing found

        // Get the window of this element
        var d = element.document || element;
        var w = d.parentWindow;

        // Loop through the handlers associated with this element, looking
        // for one with the right type and function.
        // We loop backward because the most recently registered handler
        // is most likely to be the first removed one.
        for(var i = handlers.length-1; i >= 0; i--) {
            var handlerId = handlers[i];        // get handler id
            var h = w._allHandlers[handlerId];  // get handler info
            // If handler info matches type and handler function, we found it.
            if (h.eventType == eventType && h.handler == handler)
                return i;
        }
        return -1;  // No match found
    },

    _removeAllHandlers :function( ) {
        // This function is registered as the onunload handler with
        // attachEvent. This means that the this keyword refers to the
        // window in which the event occurred.
        var w = this;

        // Iterate through all registered handlers
        for(id in w._allHandlers) {
            // Get handler info for this handler id
            var h = w._allHandlers[id];
            // Use the info to detach the handler
            h.element.detachEvent("on" + h.eventType, h.wrappedHandler);
            // Delete the handler info from the window
            delete w._allHandlers[id];
        }
    },
	
	/**
	 * 获取时间发生的元素
	 * @param {Object} e 事件
	 */
	src:function(e){
		return e?e.target:event.srcElement;
	},
	/**
	 * 阻止冒泡
	 *  
	 */
	stopPropagation:function(e){
		e?e.stopPropagation():event.cancelBubble = true;
	}

};

/**
 * BOM相关，toolkit
 */
$.bom = {
	/**
	 * 读取location.search
	 *
	 * @param {String} n=名称
	 * @return {String} search值
	 * @example
	 * 		$.bom.query('mod');
	 */
	query:function(n){
		var m = window.location.search.substr(1).match(new RegExp( "(^|&)"+n+"=([^&]*)(&|$)"));   
		return !m ? "":decodeURIComponent(m[2]);  
	},
	getHash:function(){
		
	}
}


/**
 * DOM相关，toolkit
 */
 
 


/**
 * $.dom.js: portable functions for querying window and document $.dom
 *
 * This module defines functions for querying window and document $.dom.
 *
 * getWindowX/Y( ): return the position of the window on the screen
 * getViewportWidth/Height( ): return the size of the browser viewport area
 * getDocumentWidth/Height( ): return the size of the document
 * getHorizontalScroll( ): return the position of the horizontal scrollbar
 * getVerticalScroll( ): return the position of the vertical scrollbar
 *
 * Note that there is no portable way to query the overall size of the
 * browser window, so there are no getWindowWidth/Height( ) functions.
 *
 * IMPORTANT: This module must be included in the <body> of a document
 *            instead of the <head> of the document.
 */




$.dom = {
	init:function(){		
		if (window.screenLeft) { // IE and others
			$.dom.getWindowX = function( ) { return window.screenLeft; };
			$.dom.getWindowY = function( ) { return window.screenTop; };
		}
		else if (window.screenX) { // Firefox and others
			$.dom.getWindowX = function( ) { return window.screenX; };
			$.dom.getWindowY = function( ) { return window.screenY; };
		}
		
		if (window.innerWidth) { // All browsers but IE
			$.dom.getViewportWidth = function( ) { return window.innerWidth; };
			$.dom.getViewportHeight = function( ) { return window.innerHeight; };
			$.dom.getHorizontalScroll = function( ) { return window.pageXOffset; };
			$.dom.getVerticalScroll = function( ) { return window.pageYOffset; };
		}
		else if (document.documentElement && document.documentElement.clientWidth) {
			// These functions are for IE 6 when there is a DOCTYPE
			$.dom.getViewportWidth =
				function( ) { return document.documentElement.clientWidth; };
			$.dom.getViewportHeight =
				function( ) { return document.documentElement.clientHeight; };
			$.dom.getHorizontalScroll =
				function( ) { return document.documentElement.scrollLeft; };
			$.dom.getVerticalScroll =
				function( ) { return document.documentElement.scrollTop; };
		}
		
		// These functions return the size of the document. They are not window
		// related, but they are useful to have here anyway.
		if (document.documentElement && document.documentElemnet.scrollWidth) {
			$.dom.getDocumentWidth =
				function( ) { return document.documentElement.scrollWidth; };
			$.dom.getDocumentHeight =
				function( ) { return document.documentElement.scrollHeight; };
		}
		else if (document.body.scrollWidth) {
			$.dom.getDocumentWidth =
				function( ) { return document.body.scrollWidth; };
			$.dom.getDocumentHeight =
				function( ) { return document.body.scrollHeight; };
		}		
	},
	//获取元素最终的样式
	getFinalStyle:function(elem, css){
		if(window.getComputedStyle){
			return window.getComputedStyle(elem, null)[css];
		}else if(elem.currentStyle){
			return elem.currentStyle[css];
		}else{
			return elem.style[css];
		}
	},
	height:function(elem){
		if(this.getFinalStyle(elem, "display") !== "none") {
			return elem.offsetHeight || elem.clientHeight;
		}else{
			//获取隐藏掉的函数的高度，先让它显示，获取到高度之后再隐藏，下同
			elem.style.display = "block";
			var h = elem.offsetHeight || elem.clientHeight;
			elem.style.display = "none";
			return h;
		}
	},
	width:function(elem){
		if(this.getFinalStyle(elem, "display") !== "none") {
			return elem.offsetWidth || elem.clientWidth;
		}else{
			elem.style.display = "block";
			var w = elem.offsetWidth || elem.clientWidth;
			elem.style.display = "none";
			return w;
		}
	},
			/**
		 * @description 获取页面的宽度
		 * 
		 * @return 页面的宽度
		 */
		getPageWidth: function() {
			return Math.max(document.documentElement.clientWidth, document.body.clientWidth, document.documentElement.scrollWidth, document.body.scrollWidth);
		},
		/**
		 * @description 获取页面的高度
		 * 
		 * @return 页面的高度
		 */
		getPageHeight: function() {
			return Math.max(document.documentElement.clientHeight, document.body.clientHeight, document.documentElement.scrollHeight, document.body.scrollHeight);
	},
	/**
	 * @description 创建指定标签名的Node并根据配置项设置node的一些属性
	 * 
	 * @param {String} tagName 要创建Node的tagName
	 * @param {Object} cfg 配置项,用于配置该Node的属性
	 * 		cfg:{
	 * 			class: "xxx", //class名.  等同于通过obj.className进行设置.
	 * 			style: "display:block; color:red;", //style内容. 等同于通过obj.style.cssText进行设置
	 * 			prop: "prop-value" //prop代表除class和style以外的其他各种属性. 等同于通过obj.setAttribute(prop,"prop-value")进行设置
	 * 		}
	 * @return 创建的Node
 	 * @example
	 * 		$.dom.getNode("div", {id:"test", class:"div test", style:"display:block; color:red;"});
	 */
	getNode: function(tagName,cfg){
		var node = document.createElement(tagName);
		var f = {
			"class":function(){node.className = cfg["class"];},
			"style":function(){node.style.cssText = cfg["style"];}
		}
		for(var prop in cfg){
			if(f[prop])  f[prop]();
			else node.setAttribute(prop,cfg[prop]);
		}
		
		return node;
	}
}

/**
 * 样式相关
 */
$.css = {
		/**
		 * 是否有样式名
		 *
		 * @param {Object} obj html对象
		 * @param {String} n=名称 
		 * @return {Boolean}
	 	 * @example
		 * 		$.css.addClass($('test'),'f-hover');
		 */

		hasClass: function (obj, n) {
			var rex = new RegExp("\\b" + n + "\\b");
			if (obj) {
				return rex.test(obj.className);
			} else {
				console && console.warn && console.error(obj, n);
				return false;
			}
		},
		
		/**
		 * 添加样式名
		 *
		 * @param {Object} obj html对象
		 * @param {String} n=名称 
		 * @example
		 * 		$.css.addClass($('test'),'f-hover');
		 */
		addClass: function (obj, n) {
			if (!this.hasClass(obj, n)) {
				if (obj) {
					obj.className = obj.className + " " + n;
				} else {
					console && console.warn && console.error(obj, n);
				}
			}
		},
		/**
		 * 删除样式名
		 *
		 * @param {Object} obj html对象
		 * @param {String} n=名称 
		 * @example
		 * 		$.css.addClass($('test'),'f-hover');
		 */
		removeClass:function(obj,n){
			var rex=new RegExp("\\b"+n+"\\b");
			obj.className=obj.className.replace(rex,"");
		},
		/**
		 * @description 获取节点的宽度，包括内容宽度，填充宽度，边框的宽度
		 * 
		 * @param {Object | String} node dom对象或dom的id
		 * @return ｛Number｝ 节点的宽度
		 */
		getWidth: function(node) {
			return $(node).offsetWidth;
		},
		/**
		 * @description 获取节点的宽度，包括内容高度，填充高度，边框的高度
		 * 
		 * @param {Object | String} node dom对象或dom的id
		 * @return {Number} 节点的高度
		 */
		getHeight: function(node) {
			return $(node).offsetHeight;
		},
		show:function(node){
			$.css.removeClass(node,"hide");
		},
		hide:function(node){
			$.css.addClass(node,"hide");
		}
}

/**
 * 属性相关--模仿jQuery的设置方式
 */
$.set = function(obj,name,value){
	if(!value){
		return obj.getAttribute(name);	
	}
}

$.winName={
	set:function(n,v){
		var name = window.name||"";
		if(name.match(new RegExp( ";"+n+"=([^;]*)(;|$)"))){//先验证是否存在
			window.name = name.replace(new RegExp( ";"+n+"=([^;]*)"),";"+n+"="+v);
		}else{
			window.name = name+";"+n+"="+v;
		}
	},
	get:function(n){
		//added by EdisonTsai on 14:58 2016/05/16 for fix Dom XSS bug
		var name = window.name||"";
		var v = name.match(new RegExp( ";"+n+"=([^;]*)(;|$)"));
		if(v){
			return antiDomXSS(v[1]) ? "" : v[1];
		}
		return  "";
	},
	clear:function(n){
		var name = window.name||"";
		window.name = name.replace(new RegExp( ";"+n+"=([^;]*)"),"");
	}
}

/**
 * localStorage的简单封装，不兼容的浏览器使用window.name
 * 
 * added by EdisonTsai on 12:04 2016/06/13
 */
$.storage={
	setItem:function(key,value,isJson){
		var dt = isJson === true ? JSON.stringify(value) : value;

		try{
			dt = localStorage.setItem(key, dt);
		}catch(err){
			dt = $.winName.set(key,dt);
		}

		return dt;
	},
	getItem:function(key,isJson){
		var dt;
		try{
			dt = localStorage.getItem(key);
		}catch(err){
			dt = $.winName.get(key);
		}

		if(isJson === true){
			dt = JSON.parse(dt);
		}

		return dt;
	},
	removeItem:function(key){
		try{
			localStorage.removeItem(key);
		}catch(err){
			$.winName.clear(key);
		}
	}
}

/**
 * 字符串相关操作
 */
$.str={
	getBytes:function(s){
		return s.replace(/[^\x00-\xff]/g, "xx").length;
	}
}

/**
 * html编码转码
 */
$.html = {
	encode:function(str){  
			var s = "";
			str += "";
			if(str.length == 0) return "";  
			s = str.replace(/&/g, "&amp;")  
					.replace(/</g, "&lt;")   
					.replace(/>/g, "&gt;")  
					.replace(/ /g, "&nbsp;")
					.replace(/'/g,"&apos;")
					.replace(/"/g, "&quot;");
			return s;
	},
	decode:function(str){
		var s = "";
			if(str.length == 0) return "";
			s = str.replace(/&amp;/g,"&")
					.replace(/&lt;/g,"<")
					.replace(/&gt;/g,">")
					.replace(/&nbsp;/g," ")
					.replace(/&apos;/g,"'")
					.replace(/&quot;/g,"\"");
			return s;
	}
}

/**
 * Anti DomXSS
 * 
 * added by EdisonTsai on 13:13 2016/05/16
 */
 function formatDomXSSParams(Url, map) {
    var tmpArr = new Array;
    for (var m = 0; m < Url.length; m++) {
        if (Url.charAt(m) == '&') {
            var keyLen = [3, 4, 5, 9];
            var matchFlag = 0;
            for (var n in keyLen) {
                var l = keyLen[n];
                if (m + l <= Url.length) {
                    var subLow = Url.substr(m, l).toLowerCase();
                    if (map[subLow]) {
                        tmpArr.push(map[subLow]);
                        m = m + l - 1;
                        matchFlag = 1;
                        break;
                    }
                }
            }
            if (matchFlag == 0) {
                tmpArr.push(Url.charAt(m));
            }
        } else {
            tmpArr.push(Url.charAt(m));
        }
    }
    return tmpArr.join("");
}

 function antiDomXSS(str){
 	var map = new Object();
 	//let "\"" go for json format
    var escapeChars = "'<>()`script:daex/hml;bs64,";
    for (var i = 0; i < escapeChars.length; i++) {
        var cha = escapeChars.charAt(i);
        var dec = cha.charCodeAt();
        var dec7 = dec;
        var hex = dec.toString(16);
        for (var j = 0; j < (7 - dec.toString().length); j++) {
            dec7 = "0" + dec7;
        }
        map["&#" + dec + ";"] = cha;
        map["&#" + dec7] = cha;
        map["&#x" + hex] = cha;
    }
    map["&lt"] = "<";
    map["&gt"] = ">";
    map["&quot"] = "\"";
    
    var encStr = decodeURIComponent(formatDomXSSParams(str, map));
    	var XssPattern = new RegExp("['<>()`]|script:|data:text/html;base64,");

    return XssPattern.test(encStr);
 }

/**
 * @description 面向对象相关方法(类,继承,观察者...)
 * 
 * @author knightli
 * @date 2011.12.29
 * @class
 */
$.oop = {
	
	/**
	 * @description 继承
	 * @public
	 * @param {Object} baseObj 基础对象
	 * @param {Object} override_objs 覆盖对象,可有多个该参数跟在第一个参数后面, 用来覆盖第一个参数的基础对象中的一些属性
	 * @returns {String} 继承后的对象
	 * @example
	 * 		例1:
	 *			$.oop.extend({prop1:"prop1-value", prop2:"prop2-value"});
	 * 		例2:
	 * 			var myObj = $.oop.extend({}, baseObj, {prop1:"prop1-value", prop2:"prop2-value"}, {func1:function(){},fucn2:function(){}});
	 * 		例3:
	 * 			var myObj = {myprop1:"prop1-value", myfunc1:function(){}};
	 * 			myObj = $.oop.extend(myObj, baseObj, {prop1:"prop1-value", prop2:"prop2-value"}, {func1:function(){},fucn2:function(){}});
	 * @example
	 * 说明:
	 *     该函数存在两种调用方式:
	 *     (1) 传一个参数. 结果就是将baseObj中的属性添加到$.oop空间下.返回值是$.oop这个对象  比如:
	 *            $.oop.extend({prop1:"prop1-value", prop2:"prop2-value"});
	 *            执行结果是将prop1和prop2加入到$.oop下面.
	 *            这种调用方式并不太常见, 一般用于临时往simple库的oop空间下插入方法和属性
	 *     (2) 传两个或更多参数. 结果是将第2个参数往后的对象中的属性,覆写到第一个参数baseObj中,然后将baseObj返回. 如:
	 *            var myObj = $.oop.extend(baseObj, {prop1:"prop1-value", prop2:"prop2-value"}, {prop1:"prop1-value1", func1:func1,fucn2:fucn2);
	 *            执行结果是将第2,3个参数中的属性, 写入targetObj后,返回targetObj给myObj.
	 *            这里需要注意两点:
	 *              a) 若baseObj中的属性若在后面的参数对象中也存在, 则后面的会覆写前面的.
	 *                   比如在上例中, 如果baseObj在执行前是{prop0:"prop0-basevalue", prop1:"prop1-basevalue", func1:baseFunc}
	 *                   执行后: myObj=baseObj={prop0:"prop0-basevalue",prop1:"prop1-value1",prop2:"prop2-value",func1:func1,fucn2:fucn2}
	 *              b) 最终变量myObj其实就是baseObj的引用. 而这个baseObj如果传入的也是一个引用,事实上这个引用的对象已经被修改掉了.
	 *                   这也许与你的预期不符: 我只是想要把baseObj作为父类, 让myObj去继承它, 并不想修改掉baseObj.
	 *                   如果是这样,那么这里应该这么调用:
	 *                   var myObj = $.oop.extend({}, baseObj, {prop1:"prop1-value", prop2:"prop2-value"}, {func1:function(){},fucn2:function(){}});
	 *                   使用空对象作为第一个参数, 就可以避免这种问题.
	 *                   或者myObj已经有一些属性了,就这么调用:
	 *                   var myObj = {myprop1:"prop1-value", myfunc1:function(){}};
	 *                   myObj = $.oop.extend(myObj, baseObj, {prop1:"prop1-value", prop2:"prop2-value"}, {func1:function(){},fucn2:function(){}});
	 *                   使用对象自身作为第一个参数, 也可以避免这种问题
	 *            
	 */
	extend : function(target){
		var isObject = function(o){
			return o && String(o) !== "[object HTMLDivElement]" && o.constructor === Object || String(o) === "[object Object]";
		};
		
		var isArray = function(a){
			return a && String(a) !== "[object HTMLDivElement]" && a.constructor === Array || Object.prototype.toString.call(a) === "[object Array]";
		};
		
		var isFunction = function(f){
			return f && f.constructor === Function;
		};
		
		var i;
		if(arguments.length==1){
			target = this;
			i = 0;
		}
		else{
			target = arguments[0];
			i = 1;
		}
		var deepCopy = true;
		if(typeof arguments[arguments.length-1] == 'boolean'){
			deepCopy = arguments[arguments.length-1];
		}
		//将参数的各种覆盖属性添加到target上
		while(i<arguments.length){
			var tmp = arguments[i];
			for(p in tmp){
				var tp = target[p];
				var argp = tmp[p];
				if(tp!==argp){
					//若参数的属性为对象, 则进行深拷贝(迭代)
					if(deepCopy && argp && isObject(argp) && !isArray(argp) && !argp.nodeType && !isFunction(argp)){
						tp = target[p]||{};
						target[p] = $.oop.extend(tp, argp || (argp.length==null?{}:[]))
					}
					else{
						//否则对非空属性直接进行浅拷贝
						if(argp!==void 0){
							target[p] = argp;
						}
					}
				}
			}
			i++;
		}
		return target;
	},
	/**
	 * @description 创建类
	 * @public
	 * @param {Object} [extendCfg] 可选参数, 传送该参数时,说明创建的这个类是某个类的子类.
	 *              extendCfg格式: {extend: superObj} 或者 {extend: superClass}
	 * @param {Object} argObj 要创建的类的对象原型. 其中包含的属性和方法将成为该类的原型属性和原型方法
	 * @returns {String} 创建的Class
	 * @example
	 *     该函数存在两种调用方式:
	 *     (1) 只传argObj. 结果是创建一个类, 以argObj为原型. 例如:
	 *            var SuperMan = new $.oop.Class({
	 *                init:function(name){
	 *                    this.name = name;
	 *                },
	 *                getName:function(){
	 *                    return this.name;
	 *                },
	 *                eat:function(){
	 *                    //eat
	 *                },
	 *                walk:function(){
	 *                    //walk
	 *                },
	 *                say:function(str){
	 *                    alert(str);
	 *                }
	 *            });
	 *            接下来就可以创建SuperMan的实例并调用其中的方法了.
	 *            var god = new SuperMan("god"); //注意这里new myClass1,会自动执行类的init方法,传入new的参数
	 *            M.say("Let there be light!");
	 *            
	 *     (2) 先传{extend:supperClass}, 再传argObj. 结果是创建一个类, 其原型先继承supperClass中的属性和方法,再用argObj中的属性和方法去覆盖. 接上例:
	 *            var Man = new $.oop.Class({extend:SuperMan},{
	 *                init:function(name){
	 *                    this.name = name;
	 *                    this.sex = "male";
	 *                },
	 *                intro:function(){
	 *                    this.say("[man voice]My name is "+this.name+", I'm a "+this.sex);
	 *                }
	 *            });
	 *            var Woman = new $.oop.Class({extend:SuperMan},{
	 *                init:function(name){
	 *                    this.name = name;
	 *                    this.sex = "female";
	 *                },
	 *                intro:function(){
	 *                    this.say("[woman voice]My name is "+this.name+", I'm a "+this.sex);
	 *                },
	 *                childBirth:function(sex,name){
	 *                    if(!this.children) this.children = [];
	 *                    var baby = null;
	 *                    if(sex=="male") baby = new Man(name);
	 *                    else if(sex=="female") baby = new Woman(name);
	 *                    baby.mom = this;
	 *                    this.children.push(baby);
	 *                    return baby;
	 *                }
	 *            });
	 *            接下来就可以创建Man,Woman的实例并调用其中的方法了.
	 *            var adam = new Man("Adam");
	 *            var eve = new Man("Eve");
	 *            var abel = eve.childBirth("male","Abel");
	 *            var cain = eve.childBirth("male","Cain");
	 */
	Class : function(){
		var argLen = arguments.length;
		var argObj = arguments[argLen-1];
		argObj.init=argObj.init||function(){};
		
		/**
		 * Class在new的时候会默认执行init
		 * @ignore
		 */
		var retObj = function(){return this.init.apply(this, arguments);}//retObj在new的时候会默认执行init.
		
		//两个参数: 第一个参数是继承的对象, 第二个参数是定义的类原型
		if(argLen===2){
			var extObj = arguments[0].extend;//第一个参数可以是{extend:xxx},表明要从某个对象继承
			var f=function(){};//代理对象,隔离extObj和retObj,避免new一次extObj.
			f.prototype = extObj.prototype;
			
			retObj.superClass = extObj.prototype;//添加一个superClass属性, 保存父类的原型
			/**
			 * 子类可以通过callSuper调用父类的方法
			 * @ignore
			 */
			retObj.callSuper = function(thisObj, func){
				var s = Array.prototype.slice;
				var args = s.call(arguments,2);//需要传给super方法的参数数组
				func = retObj.superClass[func];
				if(func){
					func.apply(thisObj, args.concat(s.call(arguments)) );//在thisObj上调用super方法
				}
			}
			retObj.prototype = new f();//通过代理对象f,让retObj原型继承extObj
			retObj.prototype.constructor = retObj;
			$.oop.extend(retObj.prototype, argObj);//然后再用继承方法,将argObj的方法加入retObj的原型中
			/**
			 * @ignore
			 */
			retObj.prototype.init = function(){
				argObj.init.apply(this, arguments);
			}
		}
		//一个参数: 该参数就是类原型
		else if(argLen===1){
			retObj.prototype = argObj;
		}
		return retObj;
	},
	/**
	 * @description 为调用函数绑定一个作用域(因为this很容易跟丢它原来所在的作用域，直接指向顶层的window对象)
	 *
	 * @param {Function} fn 要绑定的调用函数
	 * @param {Object} context 要绑定的作用域对象, 绑定后函数的this指针即为该对象
	 * @example
	 * 		$.oop.bind(myFunc, this);
	 */
	bind: function(fn,context){
		var s = Array.prototype.slice;
		var args = s.call(arguments,2);
		return function(){
			return fn.apply(context, args.concat(s.call(arguments)));
		}
	},
	/**
	 * @description 添加关注. 用于关注某对象的某事件,并在触发时引发参数给定的回调
	 *
	 * @param {Object} target 被观察对象
	 * @param {String} e 被观察事件名称, 表明要观察对象的哪个行为
	 * @param {Function} cbk 回调函数. 当被观察对象的上述行为触发时(一般由被观察对象通过notifyObservers触发),引发的回调函数
	 * @example
	 * 		$.oop.addObserver(targetObj, "changeName", function(newName){setNewName(newName);});
	 */
	addObserver: function(target, e, cbk){
		if (cbk) {
			e = "on" + e;
			
			if (!target._$events) target._$events = {};
			
			if(e in target._$events){
				if(target._$events[e].length == 0){
					target._$events[e] = [];
				}
			}
			else{
				target._$events[e] = [];
			}
				
			var evts = target._$events[e];
			var m = -1;
			for(var i = 0,len=evts.length; i < len; i++){
				if (evts[i] == cbk) {
					m = i;
					break;
				}
			}
			if(m===-1) evts.push(cbk);
		}
		else{
			
		}
	},
	/**
	 * @description 通知关注者们,某被观察对象的某行为发生. 用于触发关注者们自己设置的一些回调函数
	 *
	 * @param {Object} target 发生行为触发事件的对象
	 * @param {String} e 发生行为对应的事件名称. 这个值应该和添加关注时设置的事件名称保持一致,才能正确触发回调
	 * @param {Object} param 参数. 引发回调函数的输入参数
	 * @example
	 * 		$.oop.notifyObservers(targetObj, "changeName", "newName");
	 */
	notifyObservers: function(target, e, param){
		var ret = true;
		e = "on" + e;
		if(target._$events && target._$events[e]){
			var evts = $.oop.extend([],target._$events[e]);
			for(var i=0,len=evts.length; i<len; i++){
				var r = evts[i].apply(target, [param]);
				if(r===false) ret = false;
			}
			evts = null;
		}
		return ret;
	},
	/**
	 * @description 移除关注. 根据传入的参数, 将对应的关注从目标对象的关注列表上移除
	 *
	 * @param {Object} target 被观察对象
	 * @param {String} e 被观察事件名称, 表明要观察对象的哪个行为
	 * @param {Function} cbk 回调函数.
	 * @example
	 * 		$.oop.removeObserver(targetObj, "changeName");
	 */
	removeObserver: function(target, e, cbk) {
		var i, len;
		var d = false;
		var events = target._$events;
		//如果传了cbk和e,则根据e找到对应的观察者回调集合,只将集合中该cbk回调移除
		if (cbk && e) {
			if (events && (evts = events["on" + e])) {
				for (i = 0,len = evts.length; i < len; i++){
					if (evts[i] == cbk){
						evts[i] = null;
						evts.splice(i, 1);
						d = true;
						break
					}
				}
			}
		}
		//若只传了e,没有传cbk,则将e对应的观察者回调集合中的所有回调移除
		else if(e){
			if (events && (e = "on" + e, evts = events[e])) {
				len = evts.length;
				for (i = 0; i < len; i++) evts[i] = null;
				delete events[e];
				d = true;
			}
		}
		//若cbk和e都没有传,则把target的events中所有回调集合均移除
		else if (target && events) {
			for (i in events) delete events[i];//TODO: 这里是否还需要加一个循环,将events[i]中的evts置null?
			delete target._$events;
			d = true;
		}
		return d;
	}
}


g={
	searchValue:__('g_search_value','输入想要的4-10位数'),
	searchTip:__('g_search_tip','请输入4-10位数字'),
	cacheUrl:"",
	ver:__('_js_version',''),
	component:{},
	searchBlur:function(obj){
		if(obj.value==''){obj.value=g.searchValue;obj.style.color = '#aaa';}
	},
	searchFocus:function(obj){
		if(obj.value == g.searchValue){obj.value = '';obj.style.color = '#000';}
	},

	phoneClick:function(needShow){
		if(!needShow){
			$("quickReg").style.display = "none";
			$("switcher").className = "normal";
		}else{
			$("quickReg").style.display = "block";
			$("switcher").className = "hover";
			$.report.monitor("phone_zc");
		}
	},
	formSubmit:function(e){
		var str = $("serch_ipt").value;
		if(!/^[0-9]{4,10}$/.test(str)){	
			alert(g.searchTip);	
			return false;
		}else{
            window.open('http://haoma.qq.com/shop.html#num='+$("serch_ipt").value+'&from=zc');
        }
		$.report.monitor("search_qq");
		return false;
	},

	init:function(){
		if($("serch_ipt")){
		$("serch_ipt").value=g.searchValue;
		}
		//$.storage.init("zc","zc.qq.com");
	},
	
	checkVersion:function(){
		var s = document.createElement("script");
		s.src = "ver.js?v="+Math.random();
		document.getElementsByTagName("head")[0].appendChild(s);
	},
	clearCache:function (f){
		var t=document.createElement("iframe");
		t.src = "clearcache.html#"+f;
		t.style.display = "none";
		document.body.appendChild(t);
	},
	
	cb:function(ver){
		if(ver!=g.ver){
			g.clearCache(window.location+"");
		}
	},
		/**
	 * 检测是否可以快速登录
	 * @returns num 已登录的QQ数量
	 */
	getQQnum:function(){
		try{
			if(window.ActiveXObject){
				var q_hummerQtrl = new ActiveXObject("SSOAxCtrlForPTLogin.SSOForPTLogin2");  
				var vInitData = q_hummerQtrl.CreateTXSSOData();
				q_hummerQtrl.InitSSOFPTCtrl(0, vInitData); 
				var g_vOptData = q_hummerQtrl.CreateTXSSOData();
				var vResult = q_hummerQtrl.DoOperation(2, g_vOptData);
				var vAccountList = vResult.GetArray("PTALIST");
				var uListSize = vAccountList.GetSize();
				if (uListSize>0){
					return uListSize;
				}
			}else{
				if(navigator.mimeTypes["application/nptxsso"]){
					var obj = document.createElement("embed");		
					obj.type="application/nptxsso";
					obj.style.width="0px";
					obj.style.height="0px";
					document.body.appendChild(obj);
					var ok = obj.InitPVANoST();
					if(ok){
						var count = obj.GetPVACount();
						if(count>0){
							return count;
						}
					}
				}

			}
		}catch(e){
			return 0;
		}
		return 0;
	},
	openHaoma:function(type){
		switch(type){
			case 1:
			$.report.monitor("birthday_qq");
			window.open("http://haoma.qq.com/shop.html#topic=date&from=zc");
			break;
			case 2:
			$.report.monitor("love_qq");
			window.open("http://haoma.qq.com/shop.html#topic=love&from=zc");
			break;
			case 3:
			$.report.monitor("grade_qq");
			window.open("http://haoma.qq.com/static/gno/grade/grade_index.html");
			break;
			case 4:
			$.report.monitor("year_qq");
			window.open("http://haoma.qq.com/shop.html#topic=date&from=zc");
			break;
			case 5:
			$.report.monitor("mobile_qq");
			window.open("http://haoma.qq.com/shop.html#topic=phone&from=zc");
			break;
			case 6:
			$.report.monitor("fine_qq");
			window.open("http://haoma.qq.com/static/gno/mobile/mobile_index.html");
			break;
		}
	}
	
}

g.init();
g.checkVersion();
g.component.quickReg = $("quickReg") ? true : false;
$.e.add(window,'load',function(){
	if(g.component.quickReg){
		$.e.add($("switcher"),"click",function(e){
			var disp=$("quickReg").style.display;
			if(disp=="none" || disp==""){
				g.phoneClick(true);
				e.stopPropagation();
			}
		});
		$.e.add($("quickReg"),"click",function(e){
			e.stopPropagation();
		});
		$.e.add(document.body,"click",function(e){
			var disp=$("quickReg").style.display;
			if(disp!="none" && disp!=""){
				g.phoneClick(false);
			}
		});
	}
});
String.prototype.trim = function() { 
	return this.replace(/(^\s*)|(\s*$)/g, ""); 
}
//判断浏览器类型
function browser_version(){
    var ua = navigator.userAgent.toLowerCase();     
    return ua.match(/msie ([\d.]+)/)? 1 :   
    ua.match(/firefox\/([\d.]+)/) ? 3 :   
    ua.match(/chrome\/([\d.]+)/) ? 5 :   
    ua.match(/opera.([\d.]+)/) ? 9 :   
    ua.match(/version\/([\d.]+).*safari/) ? 7 : 1;   
}
/**
 * [isForeignPhone 判断国外]
 * @param  {[type]}  phoneArea [地区号]
 * @return {Boolean}       [description]
 */
function isForeignPhone(phoneArea){
	if(!/^00(86|852|853|886)/.test(phoneArea)){
		return true;
	}else{
		return false;
	}
}
/**
 * [isGanAoTaiPhone 判断港澳台手机号码]
 * @param  {[type]}  phoneArea [地区号]
 * @return {Boolean}       [description]
 */
function isGanAoTaiPhone(phoneArea){
	if(/^00(852|853|886)/.test(phoneArea)){
		return true;
	}else{
		return false;
	}
}
/**
 * [isValidPhone 检查正确的手机号码]
 * @param  {[type]}  phone [description]
 * @return {Boolean}       [description]
 */
function isRegValidPhone(phone){
	phone=phone+"";
	return /(^1[0-9]{10}$)|(^00[1-9]{1}[0-9]{3,15}$)/.test(phone);
}
$.report={
	id2attr:{
		"submit":{"id":173272,"tag":"0xD001"},
		"guard":{"id":173273,"tag":"0xD002"},
		"sendToLocal":{"id":173274}, 
		"sendToEmail":{"id":173275},
		"numReg":{"id":173276},
		"phoneReg":{"id":239277},
		"QQMailReg":{"id":173277},
		"otherMailReg":{"id":173278},
		//注册首页的pv
		"pv":{"id":173279},
		//整个站点simple的pv
		"pv_all":{"id":260714},
		//简体版pv
		"pv_chs":{"id":278037},
		//繁体版pv
		"pv_cht":{"id":278038},
		//英文版pv
		"pv_en":{"id":278039},

		//短信下行-“使用其他手机号”
		"verifyChangeTel":{"id":239278},
		//短信上行-“使用其他手机号”
		"verifyUpChangeTel":{"id":239279},
		//注册手机帐号换绑-“使用其他手机号”
		"hasbindChangeTel":{"id":239280},
		//已绑定天翼QQ-“使用其他手机号”
		"tianyiChangeTel":{"id":239281},
		//放号页气泡-“试试好用的手机辅助帐号吧”
		"paopao":{"id":239282},
		
		"phoneRegSubmitBtn":{"id":241143},
		"phoneGetVerimaBtn":{"id":241144},
		"phoneGetVerimaAgainBtn":{"id":241145},
		"phoneSubmitVerimaBtn":{"id":241146},
		"phoneVerifyNowBtn":{"id":241148},
		"phoneVerifyAgainBtn":{"id":241149},
		"phoneVerifyUpSubmitLimit":{"id":241150},
		"phoneHasbindPage":{"id":241152},
		"phoneChangebindPage":{"id":241153},
		"phoneOKLogin":{"id":241155},
		"phoneSetGuard":{"id":241156},
		"qqGetVerimaBtn":{"id":241157},
		"qqSubmitVerimaBtn":{"id":241158},
		"qqVerifyBindPhone":{"id":241159},
		"qqVerifyNowBtn":{"id":241160},
		"qqVerifyUpBindPhone":{"id":241161},
		"qqHasbindPage":{"id":241162},
		"qqChangebindPage":{"id":241163},
		
		//进入业务
		"a_qq":{"id":173280,"tag":"0xD011"},
		"a_weibo":{"id":173281,"tag":"0xD012"},
		"a_qzone":{"id":173282,"tag":"0xD013"},
		"a_email":{"id":173283,"tag":"0xD0014"},
		"a_game":{"id":173284,"tag":"0xD015"},
		"a_pengyou":{"id":173285,"tag":"0xD016"},
		"a_vip":{"id":173286,"tag":"0xD017"},
		
		//推荐业务
		"b_phoneqq":{"id":173292},
		"b_vip":{"id":173294},
		"b_email":{"id":173293},
		"b_pengyou":{"id":173295},
		"b_qzone":{"id":173296},
		"b_qshow":{"id":173297},
		"b_music":{"id":173298},
		"b_pet":{"id":173299},
		"b_qlive":{"id":173300},
		"b_lol":{"id":173301},
		"b_x5":{"id":173302},
		"b_weibo":{"id":173303},
		"b_style":{"id":173304},
		"b_mq":{"id":173305},
		"b_dnf":{"id":173306},
		"b_qplus":{"id":173307},
		"b_tenpay":{"id":173308},
		"b_speed":{"id":173309},
		"b_qtalk":{"id":173310},
		"b_qqgame":{"id":173311},
		"ad_style":{"id":173318},
		"ad_pai":{"id":173319},
		"ad_guanjia_7x":{"id":173320},
		//前台错误上报
		"404":{"id":181790},
		"500":{"id":181791},
		"no_uin":{"id":181922},
		"no_email":{"id":181923},
		"no_param":{"id":181924},
		"no_sessionCookie":{"id":182166},
		"no_uin_ie":{"id":182855},
		"no_uin_ff":{"id":182856},
		"no_uin_chrome":{"id":182857},
		"no_uin_opera":{"id":182858},
		"no_uin_safari":{"id":182859},
		"cookie_disable":{"id":183612},
		//会员链接诶统计
		"search_qq":{"id":182032},
		"birthday_qq":{"id":182033},
		"love_qq":{"id":182034},
		"grade_qq":{"id":182035},
		"year_qq":{"id":182036},
		"mobile_qq":{"id":182037},
		"fine_qq":{"id":182038},
		//天翼入口
		"phone_zc":{"id":182176},
		"tianyi":{"id":182177},
		//第三方邮箱
		"resendLink":{"id":183518},
		"renewLink":{"id":183517},
		//帮别人注册
		"helpFriend":{"id":183617},
		//密码强度
		"weakPwd":{"id":228591},
		"midPwd":{"id":228592},
		"strongPwd":{"id":228593},
		"QQHuiyuan":{"id":232857},
		//手机帐号切换到普通帐号
		"noPhone":{"id":249241},
		//初始手机帐号注册后的去向
		"phone_to_number":{"id":250660},
		"phone_to_noPhone":{"id":250661},
		"phone_to_mail":{"id":250662},
		"phone_to_submit":{"id":250663},
		"phone_count":{"id":250667},
		"number_to_phone_wording":{"id":250763},
		//初始邮箱帐号的去向
		"email_count":{"id":252155},
		"email_to_number":{"id":252156},
		"email_to_number_wording":{"id":252194},
		"email_to_phone":{"id":252157},
		"email_to_qqmail":{"id":252158},
		"email_to_submit":{"id":252159},
		//初始数字帐号注册的去向
		"number_count":{"id":252190},
		"number_to_phone":{"id":252191},
		"number_to_mail":{"id":252192},
		"number_to_submit":{"id":252193},
		//短信上下行时取消手机辅助帐号的
		"cancleqqVerifyBindPhone":{"id":254605},
		"cancleqqVerifyUpBindPhone":{"id":254606},
		//初始tab注册点击量
		"init_number_to_submit":{"id":256401},
		"init_email_to_submit":{"id":256402},
		"init_phone_to_submit":{"id":256403}
		//qqtab  绑定相关上报
		,"qqtab_bind_email_bind":{"id":259735}
		,"qqtab_bind_email_active":{"id":259736}
		,"qqtab_bind_email_lose_login":{"id":259737}
		,"qqtab_bind_phone_bind":{"id":259738}
		,"qqtab_bind_phone_active":{"id":259739}
		,"qqtab_bind_phone_send":{"id":259740}
		,"qqtab_bind_phone_conflict":{"id":259741}
		,"qqtab_bind_phone_vcode_error":{"id":259742}
		//放号页ABtest上报
		,"bindPhone_test1":{"id":265572}
		,"bindPhone_test2":{"id":265573}
		,"bindPhone_test3":{"id":265574}
		,"bindPhone_test4":{"id":265575}
		,"bindPhone_test5":{"id":265576}
		,"bindEmail_test1":{"id":265577}
		,"bindEmail_test2":{"id":265578}
		,"bindEmail_test3":{"id":265579}
		,"bindEmail_test4":{"id":265580}
		,"bindEmail_test5":{"id":265581}
		//海外手机获取短信验证码数量
		,"sea_phone_pv":{"id":312410}
		,"submit_crash_chs":{"id":2167930}
		,"submit_crash_cht":{"id":2167931}
		,"submit_crash_en":{"id":2167933}
		,"submit_crash":{"id":2168015}
		//added by EdisonTsai on 16:38 2016/06/13 for new reg of simplified Chinese
		,"chk_pg":{"id":2367543}
		,"reg_succ_pg":{"id":2367544}
		,"reg_fail_pg":{"id":2367545}
		,"capt_dwmsg":{"id":2367546}
		,"capt_upmsg":{"id":2367547}
		,"capt_dw_up_msg":{"id":2367548}
		,"capt_reg_succ":{"id":2367549}
		,"capt_dwmsg_succ":{"id":2367550}
		,"capt_upmsg_succ":{"id":2367551}
		,"capt_reg_fail":{"id":2367552}
		,"capt_reg_fail_phone":{"id":2367553}
		,"reg_phone_pv":{"id":2367554}
		,"reg_phone_dwmsg":{"id":2367555}
		,"reg_phone_upmsg":{"id":2367556}
		,"reg_phone_dw_up_msg":{"id":2367557}
		,"reg_phone_succ":{"id":2367558}
		,"reg_phone_upmsg_succ":{"id":2367559}
		,"reg_phone_fail":{"id":2367560}
		,"reg_succ_call_qq":{"id":2367561}
		,"reg_succ_down_qq":{"id":2367562}
		,"index_reg_btn":{"id":2367563}
		,"index_get_vcode_btn":{"id":2367564}
		,"index_upmsg_btn":{"id":2367565}
		,"capt_reg_btn":{"id":2367566}
		,"capt_get_vcode_btn":{"id":2367567}
		,"capt_upmsg_btn":{"id":2367568}
		,"capt_ty_11":{"id":2367569}
		,"capt_ty_12":{"id":2367570}
		,"capt_ty_21":{"id":2367571}
		,"capt_ty_41":{"id":2367572}
		,"capt_ty_51":{"id":2367573}
		,"capt_ty_52":{"id":2367574}
		,"capt_ty_61":{"id":2367575}
		,"pc_qq_reg_pg":{"id":2393993}
		,"pc_qq_reg_pg_fail":{"id":2393994}
	},
	transform:function(obj){
		if(!obj) return "";
		var arr=[];
		for(var n in obj){
			arr.push(n);
			arr.push("=");
			arr.push(obj[n]);
			arr.push("&");
		}
		return arr.join("");
	},
	monitor:function(id,obj){
		var url="/cgi-bin/common/attr?"+this.transform(this.id2attr[id])+this.transform(obj)+"r="+Math.random();
		$.http.preload(url);
		//pgvSendClick({hottag:"R1JT.ZC2."+id});
	},
	/** 
	 *  isd测试上报函数
	 * @param {array} idArr--上报点数字,写死了
	 * @param {Array}  timeArr--时间数组,包括初始时间.所有的有减去timeArr[0]来上报
	 * @example 
	 *	Nav.isd(3,[new Date()-20000,new Date()-2000,new Date()-199]);
	 */
	isd:function(timeArr){
		var idArr=[1,3,4];
		if (Math.random()>0.1) return;
		var arr = location.protocol === 'https:' ? ["https://huatuospeed.weiyun.com/cgi-bin/r.cgi?flag1=7817&flag2=2&flag3=2"] : ["http://isdspeed.qq.com/cgi-bin/r.cgi?flag1=7817&flag2=2&flag3=2"];
		var baseTime = timeArr[0];
		for(var i=0,len=idArr.length;i<len;i++){
		   	  arr.push("&");
			  arr.push(idArr[i]);
			  arr.push("=");
			  arr.push(timeArr[i+1]-baseTime);
		} 
		$.http.preload(arr.join(''));
	},
	/**
	 * [isdPwdTime 上报密码操作的时间]
	 * @param  {[type]} timeArr [description]
	 * @return {[type]}         [description]
	 */
	isdPwdTime:function(timeArr){
		var idArr=[1,2,3,4];
		var arr = location.protocol === 'https:' ? ["https://huatuospeed.weiyun.com/cgi-bin/r.cgi?flag1=7817&flag2=2&flag3=3"] : ["http://isdspeed.qq.com/cgi-bin/r.cgi?flag1=7817&flag2=2&flag3=3"];
		for(var i=0,len=idArr.length;i<len;i++){
			if(timeArr[i]>0){
				arr.push("&");
			  arr.push(idArr[i]);
			  arr.push("=");
			  arr.push(timeArr[i]);
			} 	  
		} 
		$.http.preload(arr.join(''));
	}
}
if ($GLOBALS['lang'] === 'chs') {
    $.oop.extend($.report.id2attr, {
		//靓号注册数据上报
		'l_pv': {"id": 459776}
		, 'l_enter_a': {"id": 459795}
		, 'l_enter_a_click': {"id": 459796}
		, "l_enter_b": {"id": 459797}
		, "l_enter_b_l_click": {"id": 459798}
		, 'l_enter_b_f_click': {"id": 459799}
		, 'l_f_pv': {"id": 459800}
		, 'l_f_banner': {"id": 459801}
		, 'l_index_pv': {"id": 459802}
		, 'l_pic_show': {"id": 459803}
		, 'l_pic_right': {"id": 459804}
		, 'l_pic_close': {"id": 459805}
		, 'l_stop_show': {"id": 459806}
		, 'l_stop_to_f': {"id": 459807}
		, 'l_getnum_err': {"id": 459809}
		, 'l_getnum_err_to_f': {"id": 459810}
		, 'l_phone_has_join': {"id": 459812}
		, 'l_reg_fri_num': {"id": 459813}
		, 'l_reg_change_num': {"id": 459814}
		, 'l_reg_btn': {"id": 459815}
		, 'l_reg_succ': {"id": 459816}
		, 'l_reg_fail': {"id": 459817}
		, 'l_reg_time_out': {"id": 459818}
		, 'l_under_banner_link': {"id": 459820}
		, 'l_rule_link': {"id": 459821}
		, 'l_feature_link': {"id": 459822}
		, 'l_succ_down_link': {"id": 459823}
		, 'l_succ_sq_link': {"id": 459825}
		, 'a_p_pv': {"id": 462972}
		, 'a_p_banner': {"id": 462973}
		, 'a_p_phone_pv': {"id": 462974}
		, 'a_p_phone_banner': {"id": 462975}
		, 'a_p_mail_pv': {"id": 462976}
		, 'a_p_mail_banner': {"id": 462977}
		, 'a_l_reg_num': {"id": 462978}
		, 'a_p_reg_num': {"id": 462979}
		, 'b_p_pv': {"id": 462980}
		, 'b_p_banner': {"id": 462981}
		, 'b_p_phone_pv': {"id": 462982}
		, 'b_p_phone_banner': {"id": 462983}
		, 'b_p_mail_pv': {"id": 462984}
		, 'b_p_mail_banner': {"id": 462985}
		, 'b_l_reg_num': {"id": 462986}
		, 'b_p_reg_num': {"id": 462987}
		, 'c_p_pv': {"id": 462988}
		, 'c_p_banner': {"id": 462989}
		, 'c_p_phone_pv': {"id": 462990}
		, 'c_p_phone_banner': {"id": 462991}
		, 'c_p_mail_pv': {"id": 462992}
		, 'c_p_mail_banner': {"id": 462993}
		, 'c_l_reg_num': {"id": 462994}
		, 'c_p_reg_num': {"id": 462995}
		, 'c_p': {"id": 462999}
		, 'a_cover_close': {'id': 467987}
		, 'num_with_phone': {'id': 623929}
		, 'num_without_phone': {'id': 623930}
		, 'mail_with_phone': {'id': 623931}
		, 'mail_without_phone': {'id': 623932}
		, 'num_with_phone_succ': {'id': 626507}
		, 'mail_with_phone_succ': {'id': 626508}
		, 'index_send_sms': {'id': 630744}
		, 'index_send_sms_succ': {'id': 630745}
	});
}

function langSwitch(targetLang){
	$.winName.set("type","");
	window.location.href =  window.location.pathname.split("/"+$GLOBALS['lang']+"/")[0] + "/" + targetLang + "/index.html";
	/**
	switch(targetLang){
		case "chs":
			window.location.href = "/2/chs/index.html";
			break;
		case "cht":
			window.location.href = "/cht/index.html";
			break;
		case "en":
			window.location.href = "/en/index.html";
			break;
		default:
			window.location.href = "/2/chs/index.html";
			break;
	}
	**/
}

function indexType2RegType(indexType){
	var langRegTypeArr = __('_lang_cgi_regType',[1,2,3]);
	return langRegTypeArr[indexType];
}

//打开用户反馈页面
function feedBack(id){
	if($GLOBALS['lang']=='en'){
	window.location.href = "mailto:qqimail@tencent.com?subject=Feedback from English signup page";
	}else{
	var sessionCookie=$.cookie.get("sessionCookie");
	var machineCookie=$.cookie.get("machineCookie");
	var skey=$.cookie.get("skey");
	var uin=$.cookie.get("uin");
	var url="https://support.qq.com/products/14802";
	window.open(url);
	}
}
/**
 * [_DJB sky 转bkn]
 * @param {[type]} str [description]
 */
function _DJB(str) {
	if (!str) {
			return "";
		}
		var hash = 5381;
		for (var i = 0, len = str.length; i < len; ++i) {
			hash += (hash << 5) + str.charAt(i).charCodeAt();
			}
		return hash & 0x7fffffff;
	}

/**
//tcss上报 感觉数据不准确，不怎么喜欢用
function tcssReport(id){
	try{
		if(typeof(pgvSendClick) == 'function'){
		   switch(id){
			//前台种不上
			case 1:
			pgvSendClick({hottag:"R1JT.ZC.2.NO_NEW_UIN_WEB"});
			break;
			//后台种不上
			case 2:
			pgvSendClick({hottag:"R1JT.ZC.2.NO_NEW_UIN_SERVER"});
			break;
			//都种不上
			case 3:
			pgvSendClick({hottag:"R1JT.ZC.2.NO_NEW_UIN_ALL"});
			break;
			//sessioncookie都种不上
			case 4:
			pgvSendClick({hottag:"R1JT.ZC.2.NO_PARAM"});
			break;
		}
					
	}
	}catch(e){
		
	}
}
//tcss上报初始化
$.e.add(window,"load",function(){
	var script = document.createElement("script");
	script.src = "http://pingjs.qq.com/tcss.ping.js";
	document.body.appendChild(script);
	window.setTimeout(function(){
		if(typeof(pgvMain) == 'function'){
			pgvMain();		
		}
	},500);	
});
*/
//nohost
(function(){
	var _sname = 'nohost_guid';
     var _src = '/nohost_htdocs/js/SwitchHost.js';
     if($.cookie.get(_sname) != ''){
         $.http.loadScript(_src,function(){
             var init = window['SwitchHost'] && window['SwitchHost'].init;
             init && init();
         });
     }
})();
//站点所有pv上报
$.e.add(window, "load", function () {
	$.report.monitor("pv_all");
	if ($GLOBALS['lang'] == 'chs') {
		$.report.monitor("pv_chs");
	}
	if ($GLOBALS['lang'] == 'cht') {
		$.report.monitor("pv_cht");
	}
	if ($GLOBALS['lang'] == 'en') {
		$.report.monitor("pv_en");
	}
	$.report.monitor("pv");
});
//added by EdisonTsai on 22:04 2016/05/18 for prototype of localStorage and sessionStorage and using JSON
//modified by EdisonTsai on 16:16 2016/06/13 for use $.storage to replace it
/*
try{
	Storage.prototype.setObj = function(key, obj) {
	    return this.setItem(key, JSON.stringify(obj));
	}
	Storage.prototype.getObj = function(key) {
	    return JSON.parse(this.getItem(key));
	}
}catch(err){
	//ATTR_API

}
*/


window.TCISD = {};
TCISD.pv = function (c, b, f) {
	TCISD.pv.send(c, b, f)
}
;
TCISD.reportUrl = function (c) {
	(new Image).src = c
}
;
TCISD.pv._urlSpliter = /[\?\#]/;
TCISD.pv._cookieP = /(?:^|;+|\s+)pgv_pvid=([^;]*)/i;
TCISD.pv._cookieSSID = /(?:^|;+|\s+)pgv_info=([^;]*)/i;
TCISD.pv.config = {
	webServerInterfaceURL: location.protocol == "https:" ? "https://pingfore.qq.com/pingd" : "http://pingfore.qq.com/pingd"
};
(function () {
	var c = []
		, b = -1
		, f = {
		send: function (a, e, h, d, g) {
			c.push({
				dm: a,
				url: e,
				rdm: h || "",
				rurl: d || "",
				flashVersion: g
			});
			b < 0 && (b = setTimeout(f.doSend, 150))
		},
		doSend: function () {
			clearTimeout(b);
			b = -1;
			if (c.length) {
				for (var a, e = 0; e < c.length; e++)
					if (a = f.getUrl(c.slice(0, c.length - e)),
						a.length < 2E3)
						break;
				c = c.slice(Math.max(c.length - e, 1));
				e > 0 && (b = setTimeout(f.doSend, 150));
				TCISD.reportUrl(a)
			}
		},
		getUrl: function (a) {
			for (var e = a[0], e = {
				dm: escape(e.dm),
				url: escape(e.url),
				rdm: escape(e.rdm),
				rurl: escape(e.rurl),
				flash: e.flashVersion,
				pgv_pvid: f.getId(),
				sds: Math.random()
			}, c = [], d = null, b = 1; b < a.length; b++)
				d = a[b],
					c.push([escape(d.dm), escape(d.url), escape(d.rdm), escape(d.rurl)].join(":"));
			if (c.length)
				e.ex_dm = c.join(";");
			a = [];
			for (d in e)
				a.push(d + "=" + e[d]);
			return [TCISD.pv.config.webServerInterfaceURL, "?cc=-&ct=-&java=1&lang=-&pf=-&scl=-&scr=-&tt=-&tz=8&vs=3.3&", a.join("&")].join("")
		},
		getId: function () {
			var a, c;
			(a = document.cookie.match(TCISD.pv._cookieP)) && a.length && a.length > 1 ? a = a[1] : (a = Math.round(Math.random() * 2147483647) * (new Date).getUTCMilliseconds() % 1E10,
				document.cookie = "pgv_pvid=" + a + "; path=/; domain=qq.com; expires=Sun, 18 Jan 2038 00:00:00 GMT;");
			if (!document.cookie.match(TCISD.pv._cookieSSID))
				c = Math.round(Math.random() * 2147483647) * (new Date).getUTCMilliseconds() % 1E10,
					document.cookie = "pgv_info=ssid=s" + c + "; path=/; domain=qq.com;";
			return a
		}
	};
	TCISD.pv.send = function (a, c, b) {
		a = a || location.hostname || "-";
		c = c || location.pathname;
		b = b || {};
		b.referURL = b.referURL || document.referrer;
		var d, g;
		d = b.referURL.split(TCISD.pv._urlSpliter);
		d = d[0];
		d = d.split("/");
		g = d[2] || "-";
		d = "/" + d.slice(3).join("/");
		b.referDomain = b.referDomain || g;
		b.referPath = b.referPath || d;
		f.send(a, c, b.referDomain, b.referPath, b.flashVersion || "")
	}
})();
TCISD.hotClick = function (c, b, f, a) {
	TCISD.hotClick.send(c, b, f, a)
}
;
TCISD.hotClick.send = function (c, b, f, a) {
	var a = a || {}
		, e = TCISD.hotClick
		, h = a.x || 9999
		, d = a.y || 9999
		, a = a.doc || document
		, a = a.parentWindow || a.defaultView
		, g = a._hotClick_params || {}
		, f = f || g.url || a.location.pathname || "-"
		, b = b || g.domain || a.location.hostname || "-"
		, f = [e.config.webServerInterfaceURL, "?dm=", b + ".hot", "&url=", escape(f), "&tt=-", "&hottag=", c, "&hotx=", h, "&hoty=", d, "&rand=", Math.random()];
	TCISD.reportUrl(f.join(""))
}
;
TCISD.hotClick.config = {
	webServerInterfaceURL: location.protocol == "https:" ? "https://pingfore.qq.com/pingd" : "http://pinghot.qq.com/pingd",
	domain: null,
	url: null
};
TCISD.hotClick.setConfig = function (c) {
	var c = c || {}
		, b = c.doc || document
		, b = b.parentWindow || b.defaultView;
	if (c.domain)
		b._hotClick_params.domain = c.domain;
	if (c.url)
		b._hotClick_params.url = c.url
}

$.TCSS = (function () {
	var shift = Array.prototype.shift;
	var report = function () {
		var funName = shift.call(arguments);
		if (!funName) return;
		var fun, _arguments = arguments;
		if (window.TCISD && (fun = TCISD[funName])) {
			fun.apply(null, _arguments);
		}
		return fun;
	};
	return {report: report};
})();